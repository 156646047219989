import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { RichText } from 'prismic-reactjs';
import htmlSerializer from '../../utils/htmlSerializer';

import DocumentCard from '../Common/DocumentCard';
import DocumentCardsContainer from '../Common/DocumentCardsContainer';
import PortalVideoNew from '../Common/PortalVideoNew';
import PortalVideoUpdateNew from '../Common/PortalVideoUpdateNew';

const ERMSectionNewUpdate = () => {
  const data = useStaticQuery(graphql`
    query ERMSectionNewUpdateQuery {
      prismicSalesSupportDocumentsProductCollateralErm {
        data {
          section_title
          section_description {
            richText
          }
          video_type
          wistia_video_id
          vimeo_video_id
          vimeo_video_title
          pdf_files_list {
            pdf_file_title
            pdf_download_link {
              url
            }
            coming_soon
          }
        }
      }
    }
  `);

  const doc = data.prismicSalesSupportDocumentsProductCollateralErm

  return (
    <>
      <div className='w-full max-w-screen-lg mt-6'>
        <h2 className='text-2xl md:text-3xl 2xl:text-4xl text-gray-900 font-bold mb-6'>{doc.data.section_title}</h2>
        {doc.data.section_description
        ?
        <RichText
          render={doc.data.section_description.richText}
          htmlSerializer={htmlSerializer}
        />
        :
        null
        }
      </div>

      <DocumentCardsContainer containerTitle="Downloadable Documents">
        {doc.data.pdf_files_list.map(item => {
          return (
            item.coming_soon
            ?
            <DocumentCard
              cardTitle={item.pdf_file_title}
              internalLink
              buttonComingSoon
            />
            :
            <DocumentCard
              cardTitle={item.pdf_file_title}
              buttonLink={item.pdf_download_link.url}
            />
          )
        })}
      </DocumentCardsContainer>

      <div className="w-full max-w-screen-lg grid grid-cols-1 gap-6 xl:gap-16 mt-8 xl:mt-16">
        {doc.data.video_type === 'Vimeo'
          ?
          <PortalVideoNew
            videoID={doc.data.vimeo_video_id}
            videoTitle={doc.data.vimeo_video_title}
            withoutInfo
          />
          :
          <PortalVideoUpdateNew 
            videoID={doc.data.wistia_video_id} 
            videoTitle={doc.data.vimeo_video_title} 
            withoutInfo
          />
        }
      </div>
    </>
  )
};

export default ERMSectionNewUpdate;
